import DB from '../../../../DAL/DB'
import {store} from '../../../utilities'
import {pruneResource} from '../../../action_creators'

export default async function del(id) {
  const forms = new DB.Forms()
  const formSections = new DB.FormSection(id) 

  try {
    const {data: sections = []} = await formSections.get()
    const pp = [].concat(
      sections
        .map((section) => {
          if (section.order) {
            const formFields = new DB.FormField(id, section.id)
            return section.order.map((fieldId) => {
              return formFields.delete(fieldId)
            })
          }
        })
        .filter((i) => i),
      sections.map((section) => formSections.delete(section.id)),
      forms.delete(id)
    )

    //console.log('pp to delete',pp)
    
    await Promise.all(pp.flat()) //DOE1-570
    store.dispatch(pruneResource(id))
    console.log('successfully deleted form', id)
  } catch (e) {
    console.log('failed to delete a form', e)
  }
}
