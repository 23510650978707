import { store } from './utilities';
import DB from '../DAL/DB';
import conditionGroupValidation from './Field/utils'

export const setProject = (project) => ({ type: 'SET_PROJECT', project });
export const setField = (id, value) => ({ type: 'SET_FIELD', id, value });
export const setSection = (section) => ({ type: 'SET_SECTION', section });
export const setCorrection = (id, correction = undefined) => ({ type: 'SET_CORRECTION', id, correction });
export const setActive = ({ name, value }) => ({ type: 'SET_ACTIVE', name, value });
export const setStatus = (status) => ({ type: 'SET_STATUS', status });
export const setLoading = (loading) => ({ type: 'SET_LOADING', loading });
export const photoViewer = (value) => ({ type: 'PHOTO_VIEWER', value });
export const reviewMode = (value) => ({ type: 'REVIEW_MODE', value });
export const resetState = () => ({ type: 'RESET' });
export const highlightErrors = (errors) => ({ type: 'HIGHLIGHT_ERRORS', errors });
export const drag = (dragging) => ({ type: 'DRAGGING', dragging });
export const onDragStart = (dragging) => ({ type: 'DRAGGING', dragging });
export const updateFieldsState = (value) => ({ type: 'UPDATE_FIELDS_VALUES_AUTORUBRIC', value });

export const addHiddenField = (payload) => ({ type: 'ADD_HIDDEN_FIELD', payload  })
export const removeHiddenField = (payload) => ({ type: 'REMOVE_HIDDEN_FIELD', payload  })

export const deletePhoto = (name, id) => {
  const state = store.getState();
  const field = { ...state.fields[id] };
  delete field.images[name];
  return setField(id, field);
};

export const setFollowup = ({ id, value, type, followup }) => {
  const state = store.getState();

  const field = state.fields[id];
  if (!field.value.followup) field.value.followup = { [value]: { [type]: followup } };
  else {
    if (field.value.followup[value]) field.value.followup[value] = { ...field.value.followup[value], [type]: followup };
    else field.value.followup[value] = { [type]: followup };
  }

  return setField(id, field);
};

export const setFields = (fields) => {
  const fieldsWithVisibility = addVisibilityToFields(fields);
  return { type: 'SET_FIELDS', fields: fieldsWithVisibility };
};

const addVisibilityToFields = (fields) => {
  return Object.keys(fields).reduce((acc, key) => {
    acc[key] = {
      ...fields[key],
      visible: fields[key].visible !== false,  // Default visibility to true if not explicitly false
    };
    return acc;
  }, {});
};


const isFieldVisible = (field, fields) => {
  // If no condition, default to visible
  if (!field || !field.conditionGroup || !field.conditionGroup.cgList || field.conditionGroup.cgList.length === 0) {
    return true;
  }

  // Use conditionGroupValidationFn to check visibility
  const { cgList, operand } = field.conditionGroup;
  return conditionGroupValidation(cgList, fields, operand);
};


const isFieldAnswered = (field) => {
  if (!field) return false;

  const fieldValue = field.value;

  switch (field.type) {
    case 'text-area-field':
    case 'single-input':
    case 'date-input':
    case 'time-input':
      // Answered if the text field or single input has a non-empty value
      return fieldValue !== undefined && fieldValue !== null && fieldValue.trim() !== '';

    case 'numeric-input':
      // Answered if a valid number is provided (not NaN or empty)
      return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && !isNaN(fieldValue);

    case 'radio-button':
    case 'dropdown':
      // Answered if at least one option is selected (true)
      return fieldValue && Object.keys(fieldValue.values || {}).some(key => fieldValue.values[key] === true);

    case 'multiselect':
    case 'checkboxes':
      // Answered if at least one option is selected (true) for multiselect and checkboxes
      return fieldValue && Object.keys(fieldValue.values || {}).some(key => fieldValue.values[key] === true);

    default:
      // Default: if any value is present, consider it answered
      return !!fieldValue;
  }
};


export const updateProgress = () => (dispatch, getState) => {
  const state = getState();
  const fields = state.fields;

  // Filter out non-visible fields and info-box type fields
  const visibleFields = Object.values(fields).filter(field => isFieldVisible(field, fields) && field.type !== 'info-box');
  
  const totalQuestions = visibleFields.length;
  const answeredQuestions = visibleFields.filter(isFieldAnswered).length;

  const percentComplete = totalQuestions ? Math.round((answeredQuestions / totalQuestions) * 100) : 0;

  console.log('Total Questions:', totalQuestions);
  console.log('Answered Questions:', answeredQuestions);
  console.log('Percent Complete:', percentComplete);

  // Dispatch progress update and trigger UI re-render
  dispatch({ type: 'UPDATE_PROGRESS', progress: { totalQuestions, answeredQuestions, percentComplete } });

  // Ensure state changes for fields/sections if conditions affect visibility
  dispatch({ type: 'SET_FIELDS', fields });
  dispatch({ type: 'SET_SECTION', sections: state.sections });
};

export const fetchFormDetails = (formId) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (state.project.formFetched) {
      return;
    }
    const response = await DB.Forms.get(formId);
    const formDetails = response.data;
    dispatch({ type: 'form', value: formDetails });
    if (formDetails.logo) {
      dispatch(setFormLogo(formDetails.logo.url));
    }
    dispatch({ type: 'SET_FORM_FETCHED' });
  } catch (error) {
    console.error("Error fetching form details:", error);
  }
};

export const setFormLogo = (url) => ({ type: 'SET_FORM_LOGO', payload: url });
